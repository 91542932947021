import { IconButton, IPanelStyles, Panel } from "@fluentui/react";
import classNames from "classnames";
import * as React from "react";
import { useState } from "react";
import Logo from './bookit.png'
import './Header.scss'
import Settings from "./Settings";


const panelStyles: Partial<IPanelStyles> = {
   contentInner : {
      backgroundColor: "#faf9f8"
   }
}
export default function Header({ className }: { className?: string }) {
   const title = "TeSA Room Search"
   const [settingsOpen, setSettingsOpen] = useState(false)

   return (
      <section className={classNames("header", className)}>
         <img src={Logo} alt={title} title={title} />
         <h1 className="title">{title}</h1>
         <IconButton className="settings" aria-label="Settings" iconProps={{ iconName: "Settings" }} onClick={() => setSettingsOpen(true)} />
         <Panel
            styles={panelStyles}
            className="settingsPanel"
            headerText="Settings"
            isOpen={settingsOpen}
            onDismiss={() => setSettingsOpen(false)}
            closeButtonAriaLabel="Close"
         >
            <Settings/>
         </Panel>
      </section>
   );
}



