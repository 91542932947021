import Header from "./Header";
import Progress from "./Progress";
import Logo from './bookit.png'
import css from './App.module.scss'
import { Resource, SearchFilter } from "../model";
import { addResourceIfNotPresent, getEnd, getStart, isSameEmailAddress, isSetSupported } from "../utils/office";
import SearchPanel from "./SearchPanel";
import { useCallback, useEffect, useRef, useState } from "react";
import SuggestionsList from "./SuggestionsList";
import { availabilitySearch, PluginLoginData } from "../utils/api";
import { filterResources } from "../utils/helpers";
import { useAppState } from "..";
import moment from "moment";
import { MessageBar, MessageBarType } from "@fluentui/react";

export interface AppProps {
   isOfficeInitialized: boolean;
}

export default function App({ isOfficeInitialized }: AppProps) {
   const handleClick = async (resource: Resource) => {
      const item = Office.context.mailbox.item
      if (!item) return
      await addResourceIfNotPresent(item, { emailAddress: resource.emailAddress, displayName: resource.name })
   }

   const { filter, setFilter, isSearching, suggestions, updateSuggestions } = useAvailabiltySearch()

   const { loginError, outlookState } = useAppState()

   useEffect(() => {
      if (outlookState?.start !== null && outlookState?.end !== null)
      updateSuggestions()

   }, [outlookState?.start, outlookState?.end, updateSuggestions])

   if (!isOfficeInitialized) {
      console.error("Office not initialised")
      return (
         <Progress
            title="Book-It Room Finder"
            logo={Logo}
            message="Office is not initialised. Must be run from Outlook."
         />
      );
   }

   return (
      <div className={css.app}>
         <Header className="header" />

         {loginError && <MessageBar
            messageBarType={MessageBarType.severeWarning}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
         >
            <b>User authentication error. </b> 
            <span>{`Failed to authenticate the user: ${loginError.name}  (${loginError.code})`}. {loginError.message}</span>
         </MessageBar>}

         {!loginError && <>
            <SearchPanel filter={filter} setFilter={setFilter} />
            <SuggestionsList suggestions={suggestions} resourceType={filter.resourceType} onClick={handleClick} loading={isSearching} />
         </>}

      </div>
   );
}

function useAvailabiltySearch() {
   const { user, loginData } = useAppState()

   const [isSearching, setIsSearching] = useState(false)
   const [suggestions, setSuggestions] = useState<Resource[] | null>([])
   const [filter, setFilter] = useState<SearchFilter>({
      resourceType: 'MeetingRoom',
      locations: [],
      attributes: [],
      sizeIndex: -1
   })

   const fetchCounterRef = useRef(0)

   const updateSuggestionsInternal = useCallback(async () => {
      if (!loginData) {
         console.warn("No loginData can't run availability search")
         return
      }
      setIsSearching(true)
      const current = ++fetchCounterRef.current
      let suggestions: Resource[] | null = await fetchSuggestions(filter, loginData, user)
      if (suggestions.length === 0) {
         const matches = loginData.resources.filter(res => res.roomType === filter.resourceType && filter.locations.some(l => l === res.location))
         if (matches.length === 0) {
            suggestions = null // this is sential that no resources of this type are available at this location(s)
         }
      }
      if (current !== fetchCounterRef.current) {
         return
      }
      setSuggestions(suggestions)
      setIsSearching(false)
   }, [loginData, user, filter])

   const updateSuggestionsRef = useRef(updateSuggestionsInternal)

   const onResourceAvailabilityChange = async (emailAddress: string) => {
      const item = Office.context.mailbox.item
      if (!item) {
         console.warn("No active office item can't run check availability change")
         return
      }
      if (!loginData) return

      const matchingResources = filterResources(filter, loginData.roomSizesFilter, loginData.resources)
      if (matchingResources.some(res => res.emailAddress.toLowerCase() === emailAddress)) {
         await updateSuggestionsRef.current()
      }
   }

   useAppState({onResourceAvailabilityChange})

   useEffect(() => { updateSuggestionsRef.current = updateSuggestionsInternal }, [updateSuggestionsInternal])

   const updateSuggestions = useCallback(() => updateSuggestionsRef.current(), [])

   useEffect(() => {
      updateSuggestionsInternal()
   }, [updateSuggestionsInternal])


   return { filter, setFilter, isSearching, suggestions, updateSuggestions }
}


async function fetchSuggestions(filter: SearchFilter, loginData: PluginLoginData, user: string): Promise<Resource[]> {
   
   const matchingResources = filterResources(filter, loginData.roomSizesFilter, loginData.resources)
   const items = [{
      before: 0,
      after: 0,
      emails: matchingResources.map(r => r.emailAddress),
      pinned: [],
      count: 1
   }]

   const item = Office.context.mailbox.item
   if (!item) {
      console.warn("No active office item can't run search")
      return []
   }

   const start = await getStart(item)
   const end = await getEnd(item)
   const allDay = false // await isAllDay(item)
   const duration = moment.duration(moment(end).diff(moment(start))).asMinutes()
   console.log(`searching ${moment(start).format()}`, duration, items)

   const suggestions = await availabilitySearch(user, items, [], start.valueOf(), duration, undefined, allDay, null, 10)
   console.log("suggestions", suggestions)

   for (const suggestion of suggestions) {
      console.log(`checking suggestion: start=${moment(suggestion.start).format()}`)
      if (suggestion.start === start.valueOf()) {
         const resources = loginData.resources
            .filter(r =>
               suggestion.available[0].some(e => isSameEmailAddress(r.emailAddress, e)))
         return resources
      }
   }
   return []
}






