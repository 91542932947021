import * as signalR from '@aspnet/signalr';
import { baseUrl } from './api';
import { backoff, delay } from './misc';
import { createPromiseSource } from './promise';

export type PushNotification = {
   resourceAvailabiltyChanged: {
      emailAddress: string
   }
} | {
   reload: {
      organisationId: string
   }
} | 'reconnecting' | 'reconnected'

let connection: signalR.HubConnection
let isConnected: boolean
let connected = createPromiseSource()
let wasConnected = false

export default function pushNotifications(onEvent: (ev: PushNotification) => void) {
   log('info', 'pushNotifications() enter')

   connection = new signalR.HubConnectionBuilder()
      .withUrl(`${baseUrl}/push`)
      .configureLogging(process.env.NODE_ENV === 'development' ? signalR.LogLevel.Error : signalR.LogLevel.None)
      .build()

   connection.on('ResourceAvailabilityChange', (emailAddress: string) => {
      console.log(`ResourceAvailabilityChange: ${emailAddress}`)
      onEvent({ resourceAvailabiltyChanged: { emailAddress } })
   })


   let retries = 0
   let reconnecting = false

   connection.on('ReloadOrganisation', (organisationId: string) => {
      log('info', 'reload', organisationId)
      onEvent({ reload: { organisationId } })
   })

   connection.onclose(err => {
      log(err ? 'error' : 'info', 'connection closed', err)

      isConnected = false
      if (wasConnected) {
         connected = createPromiseSource()
      }

      reconnect()
   })

   reconnect()
   return () => connection.stop()

   async function reconnect() {
      if (reconnecting) { return }
      reconnecting = true

      await connection.stop().catch(() => { })

      if (wasConnected) {
         onEvent('reconnecting')
      }

      for (; ;) {
         try {
            await delay(backoff(retries++))
            await connection.start()
            break
         } catch (e) {
            log('error', 'error connecting', e)
         }
      }

      try {
         if (wasConnected) {
            onEvent('reconnected')
            await connection.stop().catch(() => { })
            return
         }

         reconnecting = false
         retries = 0
         wasConnected = true
         isConnected = true
         connected.resolve()

         log('info', 'connected')
      } catch (e) {
         log('error', 'error subscribing', e)
         reconnect()
      }
   }
}

export async function reconnect() {
   if (isConnected) {
      isConnected = false
      wasConnected = false
      connected = createPromiseSource()

      try {
         await connection.stop()
      } catch (e) {
         log('error', 'error stopping the connection', e)
      }
   }
   await connected.promise
}

export async function subscribe(...emails: string[]) {
   await connected.promise
   await connection.invoke('Subscribe', emails)
}

export async function unsubscribe(...emails: string[]) {
   await connected.promise
   await connection.invoke('Unsubscribe', emails)
}


function log(type: 'info' | 'error', ...args: any[]) {
   // if (process.env.NODE_ENV === 'development') {
   console[type]('push', ...args)
   // }
}
