import App from "./components/App";
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import 'promise-polyfill/src/polyfill'
import 'react-app-polyfill/ie11';
import { useContext, useEffect, useRef, useState } from "react";
import * as Api from "./utils/api";
import { addItemChangedEventHandler, getEnd, getResources, getStart, instanceOfOfficeError, ItemChangedEventArgs, ItemType, OfficeError } from "./utils/office";
import { Resource } from "./model";
import pushNotifications, { PushNotification } from "./utils/pushNotifications";

initializeIcons();

let isOfficeInitialized = false;

const render = () => {
   ReactDOM.render(
      <ThemeProvider>
         <AppStateProvider>
            <App isOfficeInitialized={isOfficeInitialized} />
         </AppStateProvider>
      </ThemeProvider>
      , document.getElementById("root")
   );
};

/* Render application after Office initializes */
Office.initialize = () => {
   isOfficeInitialized = true;
   render();
};

export interface OutlookItemState {
   start: Date
   end: Date
   resources: string[]
}

export interface EventCallbacks {
   onReconnecting?: () => void
   onReconnected?: () => void
   onResourceAvailabilityChange?: (email: string) => void
}

interface AppStateType {
   user: string
   loginData: Api.PluginLoginData | null
   loginError: OfficeError | null
   outlookState: OutlookItemState
   register(callbacks: EventCallbacks): () => void
}

const AppContext = React.createContext<AppStateType | null>(null)

function AppStateProvider({ children }: React.PropsWithChildren<{}>) {
   const user = Office.context.mailbox.userProfile.emailAddress
   const [loginData, setLoginData] = useState<Api.PluginLoginData | null>(null)
   const [loginError, setLoginError] = useState<OfficeError | null>(null)
   const [outlookState, setOutlookState] = useState<OutlookItemState>({ resources: [], start: new Date(0), end: new Date(0) })

   const resources = useRef<Resource[] | null>(null)
   const onEventCallbacks = useRef<EventCallbacks[]>([])

   // login and get the login data
   useEffect(() => {
      async function login(user: string) {

         if (!Office.context.requirements.isSetSupported('IdentityAPI', '1.3')) {
            setLoginError({ code: -2, name: "Unsupproted Outlook version", message: "This version of Outlook cannot run this add-in" })
         }

         try {
            let data = await Api.whoAmI()

            if (data === null) {
               const options = { allowSignInPrompt: true, allowConsentPrompt: true, forMSGraphAccess: false };
               const token = await OfficeRuntime.auth.getAccessToken(options)
               console.log("getAccessToken", token)
               data = await Api.login(token)
            }
            if (data) {
               setLoginData(data)
               resources.current = data.resources
            } else {
               setLoginError({ code: -1, name: "Failed to login", message: "Unable to login to Book-It. If this problem persists please contact your administrator." })

            }
         } catch (e) {
            if (instanceOfOfficeError(e)) {
               console.error(`Failed to getAccessToken: code=${e.code}, name=${e.name}, message=${e.message}`)
               setLoginError(e)
            } else {
               console.error('Failed to getAccessToken', e)
               setLoginError({ code: -1, name: "Unknown", message: "An unkown error occured" })
            }
         }
      }
      if (user !== null) {
         login(user)
      }
   }, [user])

   // once we have loginData we can connect to SignalR
   useEffect(() => {

      function onEvent(notification: PushNotification) {
         if (notification === 'reconnecting') {
            console.log("SignalR reconnecting")
            for (const onEvent of onEventCallbacks.current) {
               onEvent.onReconnecting?.()
            }
            return
         }

         if (notification === 'reconnected') {
            console.log("SignalR reconnected")
            for (const onEvent of onEventCallbacks.current) {
               onEvent.onReconnected?.()
            }
            return
         }

         if ('resourceAvailabiltyChanged' in notification) {
            const { emailAddress } = notification.resourceAvailabiltyChanged
            for (const onEvent of onEventCallbacks.current) {
               onEvent.onResourceAvailabilityChange?.(emailAddress)
            }
         }

         if ('reload' in notification) {
            const { organisationId } = notification.reload
            if (loginData?.organisationId && loginData.organisationId === organisationId) {
               window.location.reload()
            }
         }
      }
      async function connectPush() {
         await pushNotifications(onEvent)
      }

      if (loginData?.organisationId) {
         connectPush()
      }

   }, [loginData?.organisationId])


   useEffect(() => {
      let removeHandlerFunc: ((item: ItemType) => void) | null = null

      function isResource(att: Office.EmailAddressDetails) {
         if (resources.current === null) {
            return true
         }
         return resources.current.some(r => r.emailAddress.toLowerCase() === att.emailAddress.toLowerCase())
      }

      function handleItemChanged(args: ItemChangedEventArgs) {
         if (args.type === 'AppointmentTimeChanged') {
            setOutlookState(os => ({ ...os, start: args.start, end: args.end }))
         } else if (args.type === 'ResourcesChanged') {
            setOutlookState(os => ({ ...os, resources: args.resources }))
         }
      }

      async function addHandlers(i: ItemType) {
         removeHandlerFunc = await addItemChangedEventHandler(i, handleItemChanged, isResource)
      }

      async function removeHandlers() {
         const i = Office.context.mailbox.item
         if (!i) return
         removeHandlerFunc?.(i)
      }
      async function loadInitailState(i: ItemType) {
         const start = await getStart(i)
         const end = await getEnd(i)
         setOutlookState(os => ({ ...os, start: start, end: end }))
         const resources = await getResources(i, isResource)
         setOutlookState(os => ({ ...os, resources: resources }))
      }
      const item = Office.context.mailbox.item
      if (!item) return
      addHandlers(item)
      loadInitailState(item)
      return () => { removeHandlers() }
   }, [])

   function register(callbacks: EventCallbacks) {
      onEventCallbacks.current.push(callbacks)

      return () => {
         const index = onEventCallbacks.current.indexOf(callbacks)
         if (index > -1) {
            onEventCallbacks.current.splice(index, 1);
         }
      }
   }

   return <AppContext.Provider value={{ user, loginData, loginError, outlookState, register }}>
      {children}
   </AppContext.Provider>

}

export function useAppState(callbacks?: EventCallbacks): Omit<AppStateType, "register"> {
   const data = useContext(AppContext)
   const callbacksRef = useRef(callbacks)

   useEffect(() => {
      callbacksRef.current = callbacks
   })

   if (!data) throw new Error("Must use <AppStateProvider/> component")
   useEffect(() => {
      const callbacks: EventCallbacks = {
         onReconnecting: (...args) => callbacksRef.current?.onReconnecting?.(...args),
         onReconnected: (...args) => callbacksRef.current?.onReconnected?.(...args),
         onResourceAvailabilityChange: (...args) => callbacksRef.current?.onResourceAvailabilityChange?.(...args)
      }
      return data.register(callbacks)
   }, [data])
   return data
}




