
export type ItemType = NonNullable<typeof Office.context.mailbox.item>

export type ItemChangedEventArgs = {
   type: 'AppointmentTimeChanged',
   start: Date,
   end: Date
} | {
   type: 'ResourcesChanged',
   resources: string[]
}

interface OutlookState {
   start: Date
   end: Date
   resources: string[]
}

const TEST_USE_POLLING = false
const POLL_INTERVAL = 1000

export function getEmailAddress(item: ItemType) {
   return Office.context.mailbox.userProfile.emailAddress
}


async function buildResources(item: ItemType, isResource: (attendee: Office.EmailAddressDetails) => boolean, locationDetails: Office.LocationDetails[] | null) {
   if (locationDetails === null) {
      locationDetails = (await getLocationDetails(item)) ?? []
   }
   const locations = locationDetails.filter(isLocationRoom).map(loc => loc.emailAddress.toLowerCase())
   const required = (await getRequiredAttendees(item)).filter(att => isResource(att)).map(att => att.emailAddress.toLowerCase())
   return locations.concat(required).filter((v, i, a) => a.indexOf(v) === i)
}
export async function getResources(item: ItemType, isResource: (attendee: Office.EmailAddressDetails) => boolean) {
   return buildResources(item, isResource, null)
}

export function addItemChangedEventHandler(outlookItem: ItemType, handler: (args: ItemChangedEventArgs) => void, isResource: (attendee: Office.EmailAddressDetails) => boolean) {
   let removeAppointmentTimeChanged: ((item: ItemType) => void) | null = null
   let removeRecipientsChanged: ((item: ItemType) => void) | null = null
   let removeEnhancedLocationsChanged: ((item: ItemType) => void) | null = null

   function handleTimeChanged(args: Office.AppointmentTimeChangedEventArgs) {
      console.log("appointmentTimeChange", args)
      handler({ type: 'AppointmentTimeChanged', start: args.start, end: args.end })
   }
   async function handleRecipientsChanged(args: Office.RecipientsChangedEventArgs) {
      console.log("recipientsChange", args)
      if (args.changedRecipientFields.requiredAttendees || args.changedRecipientFields.resources) {
         const item = Office.context.mailbox.item
         if (!item) return
         handler({ type: 'ResourcesChanged', resources: await buildResources(item, isResource, null) })
      }
   }
   async function handleEnhancedLocationsChanged(args: Office.EnhancedLocationsChangedEventArgs) {
      console.log("enhancedLocationsChanged", args)
      const item = Office.context.mailbox.item
      if (!item) return
      // update requiredAttendees as well because when removing froom enhanced location outlook moves a copy to requiredAttendees but doesn't notify
      handler({ type: 'ResourcesChanged', resources: await buildResources(item, isResource, args.enhancedLocations) })
   }


   async function addHandlers(i: ItemType) {
      if (TEST_USE_POLLING || !isSetSupported('1.7')) {
         console.log(`Change eventss not support falling back to polling`)
         let prev: OutlookState | null = null
         let counter = 0;
         const intervalID = setInterval(async () => {
            const item = Office.context.mailbox.item
            if (!item) return
            const current = ++counter
            const curr = {
               start: await getStart(i),
               end: await getEnd(i),
               resources: await buildResources(i, isResource, null)
            }
            // first time signal everything
            if (prev === null) {
               handler({ type: 'AppointmentTimeChanged', start: curr.start, end: curr.end })
               handler({ type: 'ResourcesChanged', resources: curr.resources })
            } else {
               if (current === counter && (prev.start.valueOf() !== curr.start.valueOf() || prev.end.valueOf() !== curr.end.valueOf())) {
                  handler({ type: 'AppointmentTimeChanged', start: curr.start, end: curr.end })
               }
               if (current === counter && (prev.resources.length !== curr.resources.length || !prev.resources.every((value, index) => value === curr.resources[index]))) {
                  handler({ type: 'ResourcesChanged', resources: curr.resources })
               }

            }
            prev = curr
         }, POLL_INTERVAL)

         return () => clearInterval(intervalID)
      } else {
         removeAppointmentTimeChanged = addAppointmentTimeChanged(i, handleTimeChanged)
         removeRecipientsChanged = addRecipientsChanged(i, handleRecipientsChanged)
         removeEnhancedLocationsChanged = addEnhancedLocationsChanged(i, handleEnhancedLocationsChanged)
         return () => {
            const item = Office.context.mailbox.item
            if (!item) return
            removeAppointmentTimeChanged?.(item)
            removeRecipientsChanged?.(item)
            removeEnhancedLocationsChanged?.(item)
         }
      }
   }

   return addHandlers(outlookItem)
}

function addItemChangedHandler<TArgs>(item: ItemType, handler: (args: TArgs) => void, eventType: Office.EventType,
   versionRequired: string, getArgs: (item: ItemType) => Promise<TArgs>, isMatch: (prev: TArgs, curr: TArgs) => boolean): (i: ItemType) => void {
   if (!isSetSupported(versionRequired)) {
      console.log(`${eventType} not support falling back to polling`)
      let prev: TArgs | null = null
      let counter = 0;
      const intervalID = setInterval(async () => {
         const current = ++counter
         const curr = await getArgs(item)
         if (current === counter && (prev === null || !isMatch(prev, curr))) {
            handler(curr)
         }
         prev = curr
      }, 1000)

      return () => clearInterval(intervalID)

   }
   item.addHandlerAsync(eventType, handler, (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
         console.error(`Failed to add handler for ${eventType}`)
      }
   })
   return (i: ItemType) => i.removeHandlerAsync(eventType, (asynResult) => {
      if (asynResult.status === Office.AsyncResultStatus.Failed) {
         console.error(`Failed to remove handler for ${eventType}`)
      }
   })
}

export function addAppointmentTimeChanged(item: ItemType, handler: (args: Office.AppointmentTimeChangedEventArgs) => void): (i: ItemType) => void {
   return addItemChangedHandler(item, handler, Office.EventType.AppointmentTimeChanged, '1.7',
      async (i) => {
         const item: Office.AppointmentTimeChangedEventArgs = {
            start: await getStart(i),
            end: await getEnd(i),
            type: 'olkAppointmentTimeChanged'
         }
         return item
      },
      (prev, curr) => prev.start !== curr.start || prev.end !== curr.end
   )
}

export function addRecipientsChanged(item: ItemType, handler: (args: Office.RecipientsChangedEventArgs) => void): (i: ItemType) => void {
   return addItemChangedHandler(item, handler, Office.EventType.RecipientsChanged, '1.7',
      async (i) => {
         // fallback only supports required attendees
         const item: Office.RecipientsChangedEventArgs & { requiredAttendees: Office.EmailAddressDetails[] } = {
            requiredAttendees: await getRequiredAttendees(i),
            changedRecipientFields: {
               bcc: false,
               cc: false,
               optionalAttendees: false,
               requiredAttendees: true, // mark it changed but event will only be retuyrned if isMatch returns false
               resources: false,
               to: false
            },
            type: 'olkRecipientsChanged'
         }
         return item
      },
      (prev, curr) => prev.requiredAttendees.length === curr.requiredAttendees.length && prev.requiredAttendees.every((value, index) => value === curr.requiredAttendees[index])
   )
}

export function addEnhancedLocationsChanged(item: ItemType, handler: (args: Office.EnhancedLocationsChangedEventArgs) => void) {
   if (!isEnhancedLocationSuported()) {
      console.warn("EnhancedLocationsChangedEventArgs not support")
      return null
   }
   item.addHandlerAsync(Office.EventType.EnhancedLocationsChanged, handler, (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
         console.error("Failed to add handler for EnhancedLocationsChanged")
      }
   })
   return (i: ItemType) => item.removeHandlerAsync(Office.EventType.EnhancedLocationsChanged, (asynResult) => {
      if (asynResult.status === Office.AsyncResultStatus.Failed) {
         console.error("Failed to remove handler for EnhancedLocationsChanged")
      }
   })
}


export function isEnhancedLocationSuported(): boolean {
   return isSetSupported('1.8');
}


export async function addResourceIfNotPresent(item: ItemType, recipient: Office.EmailUser): Promise<void> {
   let attendees = await getRequiredAttendees(item)
   if (attendees.some(att => isSameEmailAddress(att.emailAddress, recipient.emailAddress))) {
      console.log("Add: Already in attendees not adding")
      return
   }
   let locations = await getLocationDetails(item)
   if (locations !== null && locations.some(loc => isLocationRoom(loc) && isSameEmailAddress(loc.emailAddress, recipient.emailAddress))) {
      console.log("Add: Already in locations not adding")
      return
   }
   await addRoom(item, recipient)
}

export function getRequiredAttendees(item: ItemType): Promise<Office.EmailAddressDetails[]> {
   return toPromise((cb) => item.requiredAttendees.getAsync(cb))
}


export function getLocationDetails(item: ItemType): Promise<Office.LocationDetails[] | null> {
   if (!isSetSupported('1.8')) {
      return Promise.resolve([])
   }
   return toPromise((cb) => item.enhancedLocation ? item.enhancedLocation.getAsync(cb) : [])
}
export function getStart(item: ItemType): Promise<Date> {
   return toPromise((cb) => item.start.getAsync(cb))
}

export function getEnd(item: ItemType): Promise<Date> {
   return toPromise((cb) => item.end.getAsync(cb))
}

export function addRoom(item: ItemType, recipient: Office.EmailUser | Office.EmailAddressDetails): Promise<void> {

   if (isSetSupported('1.8') && item.enhancedLocation) {
      const locationId = {
         id: recipient.emailAddress,
         type: Office.MailboxEnums.LocationType.Room
      }
      console.log("Adding room via enhanced locations")
      return toPromise((cb) => item.enhancedLocation.addAsync([locationId], cb))
   }

   console.log("Adding room via required attendees")

   return toPromise((cb) => item.requiredAttendees.addAsync([recipient], cb))
}

export function isSetSupported(version: string) {
   return Office.context.requirements.isSetSupported('Mailbox', version)
}

export function isSameEmailAddress(email1: string, email2: string) {
   return email1.localeCompare(email2, undefined, { sensitivity: 'accent' }) === 0
}


export type AsyncCallback<TResult> = (asyncResult: Office.AsyncResult<TResult>) => void
export function toPromise<T>(work: (callback: AsyncCallback<T>) => void): Promise<T> {
   return new Promise((resolve, reject) => {
      work((asyncResult: Office.AsyncResult<T>) => {
         if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            reject(asyncResult.error);
         } else {
            resolve(asyncResult.value)
         }
      })
   });
}


export function isLocationRoom(location: Office.LocationDetails) {
   return location.locationIdentifier.type === Office.MailboxEnums.LocationType.Room
}

export interface OfficeError {
   code: number
   message: string
   name: string
}


export function instanceOfOfficeError(object: any): object is OfficeError {
   return (typeof object === 'object') && 'code' in object && 'message' in object && 'name' in object
}

export function getOfficeSupportInfo() {
   let mailboxAPIVer: string | undefined
   for (let i = 0; i < 20; ++i) {
      const ver = `1.${i}`
      if (!isSetSupported(ver)) {
         mailboxAPIVer = `1.${i - 1}`
         console.info(`Mailbox API level support: ${mailboxAPIVer}`)
         break;
      }
   }
   let identityAPIVer: string | undefined

   for (let i = 0; i < 20; ++i) {
      const ver = `1.${i}`
      if (!Office.context.requirements.isSetSupported('IdentityAPI', ver)) {
         identityAPIVer = `1.${i - 1}`
         console.info(`Identity API level support: ${identityAPIVer}`)
         break;
      }
   }

   const account = Office.context.mailbox.userProfile.emailAddress

   return { ...Office.context.diagnostics, mailboxAPIVer: mailboxAPIVer ?? "Unknown", identityAPIVer: identityAPIVer ?? "Unknown", account}
}
