import { FontIcon } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { getOfficeSupportInfo } from "../utils/office";
import css from './Settings.module.scss'


export default function Settings() {

   const [supportInfo] = useState<ReturnType<typeof getOfficeSupportInfo>>(getOfficeSupportInfo())

   return <div className={css.settings}>

      <Card title="About" iconName="info">
         <div className={css.about}>
            <span className={css.appName}>IPFX Book-It Outlook Add-In</span>
            <span>Version {process.env.REACT_APP_VERSION ?? "1.0.0-dev"}</span>
            <span>IPFX Ltd.</span>
            <span>Copyright &copy; 2021</span>
            <span>All rights reserved.</span>
         </div>
      </Card>

      <Card title="Support Information" iconName="diagnostic">
         <div className={css.about}>
            <h5>Account</h5>
            <span>{supportInfo.account}</span>
            {supportInfo.platform !== Office.PlatformType.OfficeOnline && <>
               <h5>Outlook Version</h5>
               <span>{supportInfo.version}</span>
            </>}
            <h5>Outlook Platform</h5>
            <span>{supportInfo.platform}</span>
            <h5>Mailbox API</h5>
            <span>{supportInfo.mailboxAPIVer}</span>
            <h5>Identity API</h5>
            <span>{supportInfo.identityAPIVer}</span>
         </div>
      </Card>

   </div>
}

interface CardProps {
   title: string
   iconName: string
}
function Card({ title, iconName, children }: React.PropsWithChildren<CardProps>) {
   return <div className={css.card}>
      <div className={css.columns}>
         <FontIcon className={css.icon} iconName={iconName} />
         <div className={css.content}>
            <p className={css.title}>{title}</p>
            {children}
         </div>
      </div>
   </div>

}
