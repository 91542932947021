import { Icon, Shimmer, ShimmerElementsGroup, ShimmerElementType } from "@fluentui/react"
import classNames from "classnames"
import { useMemo } from "react"
import { useAppState } from ".."
import { getResourceTypeName, Resource, ResourceType } from "../model"
import FormSection from "./FormSection"
import { ResourceTypeIcon } from "./ResourceIcon"
import css from './SuggestionsList.module.scss'

interface SuggestionsListProps {
   onClick: (resource: Resource) => void
   suggestions: Resource[] | null
   resourceType: ResourceType
   loading: boolean
}


const wrapperStyles = { display: 'flex', flexFlow: 'column' };


const getCustomElements = (): JSX.Element => {
   return (
      <div style={wrapperStyles}>
         <ShimmerElementsGroup
            shimmerElements={[
               { type: ShimmerElementType.circle, height: 40 },
               { type: ShimmerElementType.gap, width: 16, height: 40 },
            ]}
         />
      </div>
   );
}

export default function SuggestionsList({ suggestions, onClick, loading, resourceType }: SuggestionsListProps) {

   const {outlookState } = useAppState()

   const disabledResource = useMemo(() => {
      const res = outlookState.resources.filter((v, i, a) => a.indexOf(v) === i)
      console.log("Disabled resources now: ", res)
      return res
   }, [outlookState.resources])

   return <FormSection label='Suggested Resources'>
      {loading && [0, 1, 2, 3].map(i => <Shimmer className={css.shimmer} customElementsGroup={getCustomElements()} width="300" isDataLoaded={false} key={i}/>)}
      {!loading && suggestions === null && <span className={css.empty}>{`${getResourceTypeName(resourceType, 2)} are not available in this location`}</span>}
      {!loading && suggestions && suggestions.length === 0 && <span className={css.empty}>Nothing is available at this time</span>}
      {!loading && suggestions && suggestions.length > 0 && <ul>
         {suggestions.map(res =>
            <ResourceItem resource={res} key={res.emailAddress} onClick={onClick} disabled={disabledResource.some(e => e === res.emailAddress)} />)
         }
      </ul>}
   </FormSection>
}

interface ResourceItemProps {
   resource: Resource
   onClick: (resource: Resource) => void
   disabled: boolean
}

function ResourceItem({ resource, onClick, disabled }: ResourceItemProps) {
   return <li className={classNames(css.resourceItem, disabled && css.disabled)} id={resource.emailAddress} onClick={() => onClick(resource)}>
      <div className={css.icon}><ResourceTypeIcon type={resource.roomType} /></div>
      <div className={css.info}>
         <span>{resource.name}</span>
         {resource.roomType === 'MeetingRoom' && <div className={css.roomSize}>
            <Icon className={css.sizeIcon} iconName='Contact' />
            <span>{resource.seats}</span>
         </div>
         }
      </div>
   </li>
}

